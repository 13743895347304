import { PriceRule } from 'features/pdp/components/ProductDetails/ProductPrice';
import { ProductAttribute } from 'features/pdp';
import { GiftcardAmount, TierPrices } from 'features/pdp/types/event';
import { StrikethroughValues } from './StrikethroughValues';
import { MediaObject } from './media';
import { graphql } from 'gatsby';
import { BaseCategory } from './Category';

export const GiftCardProductType = 'giftcard';

export interface ProductFilter {
  name: string;
  values: string[];
}

export type UrlsType = {
  magento: string;
  media: string | null | undefined;
  flash: string | null | undefined;
  project: string | null | undefined;
};

export type MediaType = MediaObject[];
export type Configuration = {
  images?: Record<string, unknown>;
  attributes?: Record<string, unknown>;
  optionPrices?: Record<string, unknown>;
};
export type ConfigurableAttribute = {
  id: number | string;
  label: string;
  title?: string;
  type: string;
  code?: string;
  options:
    | {
    [id: string]: {
      type: number;
      value: string;
      label: string;
    };
  }
    | null
    | undefined;
};

export type CustomOption = {
  default_title: string;
  display_name: any;
  is_require: boolean;
  depends_on_option?: any;
  depends_on_values?: any;
  price: any;
  name: string;
  values: CustomOptionData[];
  filterAttribute: string;
  use_case_option?: string;
  use_case_show_parent_on_clp: boolean;
  react_pdp_input_type: string;
};

export type CustomOptionData = {
  default_title: string;
  display_name: any;
  hover_image: string;
  hover_description: any;
  hover_label: any;
  hover_price: any;
  in_stock: string;
  visible_on_pdp: string;
  depends_on_option?: any;
  depends_on_values?: any;
  use_case_default_image_url?: string;
  use_case_product_title?: string;
  clp_position: string;
  label: string;
  price: string;
  filterValue: string;
};

export type Rating = {
  totalReviews: number;
  score: number;
};

interface ProductCallouts {
  callout1?: string;
  callout2?: string;
  callout3?: string;
}

export interface Product {
  id?: string;
  productId: number;
  url: string;
  sku: string;
  name: string;
  shortDescription?: string;
  inStock?: boolean;
  status: number;
  description: string;
  descriptionAuDifference?: string;
  editorVersion: string;
  isEditable: boolean;
  enterEditor: boolean;
  productType?: string | boolean;
  reportingProductCategory: string;
  hammerCategory?: string;
  reportingProductType: string;
  reportingProductLine: string;
  badges: string[];
  price: number;
  lowestPrice: number;
  tierPrices?: TierPrices;
  image: string;
  thumbnail: string;
  clpLink?: string;
  isAfterpayProduct: boolean;
  urlKey: string;
  metaTitle: string;
  metaDescription: string;
  canonicalUrl: string;
  mediaUrl?: string;
  gatsbyPageExists: boolean;
  clpHoverImage: string;
  clpHoverVideoId: string;
  clpCarouselImages: string[];
  giftcardAmounts?: GiftcardAmount[];
  catalogPriceRules?: PriceRule[];
  visibility: string;
  filters: ProductFilter[];
  livePreviewAttributes: string[];
  customOptions: CustomOption[];
  attributes?: ProductAttribute[];
  addToCartLabel: string;
  stickyCTAMessaging: string;
  promotionalMessage?: string;
  accordionTitleShippingTime?: string;
  productDetailsExtraLabel?: string;
  shippingInfoHtml?: string;
  detailsHtml?: string;
  pagePricingDetails?: string;
  additionalData: string;
  FAQHtml?: string;
  categories?: BaseCategory[];
  hasDustJacket?: boolean;
  strikethroughValues?: StrikethroughValues;
  customProductType?: string;
  relatedProducts?: RelatedProduct[];
  shippingInformation: string;
  shippingAlert: string;
  sla: string;
  outOfStockPermutations: string[];
  productCallout: ProductCallouts | null;
  media: MediaType;
  configuration: Configuration;
  urls?: UrlsType;
  addToCartUrl?: string;
  serializedConfiguration?: string;
}
export interface ProductNode {
  node: Product;
}
export interface RelatedProduct {
  position: number;
  productRelationType: 'upsell' | 'related' | 'crosssell';
  product: Product;
}

export type CategorySLA = '1 Day SLA' | '2 Day SLA' | '3 Day SLA' | '4 Day SLA' | '5 Day SLA' | '6 Day SLA';

export const isCategorySLA = (categoryName: string): categoryName is CategorySLA => {
  return ['1 Day SLA', '2 Day SLA', '3 Day SLA', '4 Day SLA', '5 Day SLA', '6 Day SLA'].includes(categoryName);
};

export const BaseProductFragment = graphql`
  fragment BaseProduct on Product {
    sku
    name
    inStock
    description
    hammerCategory
    editorVersion
    outOfStockPermutations
    detailsHtml
    sla
    livePreviewAttributes
    descriptionAuDifference
    shippingInfoHtml
    customProductType
    FAQHtml
    isEditable
    tierPrices {
      price
      quantity
    }
    giftcardAmounts {
      value_id
      website_id
      value
      row_id
      attribute_id
      website_value
    }
    reportingProductCategory
    reportingProductType
    isAfterpayProduct
    additionalData
    reportingProductLine
    addToCartLabel
    badges
    urlKey
    price
    lowestPrice
    image
    metaTitle
    customOptions {
      name
      filterAttribute
      use_case_option
      display_name
      depends_on_option
      depends_on_values
      is_require
      react_pdp_input_type
      values {
        label
        price
        display_name
        hover_price
        hover_image
        depends_on_option
        depends_on_values
        hover_description
        hover_label
        in_stock
        visible_on_pdp
        filterValue
        use_case_default_image_url
        use_case_product_title
      }
    }
    attributes {
      id
      name
      label
      price
      displayName
      type
      hidden
      required
      dependsOnOption
      dependsOnValues
      options {
        id
        name
        title
        label
        price
        displayName
        displayLabel
        hoverDescription
        hoverLabel
        hoverImage
        hoverPrice
        mediaUrl
        disabled
        hidden
        dependsOnOption
        dependsOnValues
      }
    }
    media {
      thumb
      img
      full
      caption
      position
      isMain
      type
      videoUrl
      videoId
    }
    metaDescription
    productId
    canonicalUrl
    gatsbyPageExists
    clpHoverImage
    clpHoverVideoId
    clpCarouselImages
    visibility
    status
    clpLink
    filters {
      name
      values
    }
    url
    urls {
      flash
      project
      magento
      media
    }
    strikethroughValues {
      discountAmount
      discountType
      promoCode
    }
  }
`;
