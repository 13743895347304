import type { UseFormWatch } from 'react-hook-form';
import { ProductAttribute, ProductAttributeOption } from './attrs';
import { PDPFields } from '..';
import { buildPermutationSkuFromValues } from 'features/pdp/helpers/selectors';
import { Product } from 'types/Product';

export type HydratedPDPFields = Record<keyof PDPFields, ProductAttributeOption>;

type FieldValues = {
  [key: string]: string;
};

type InventoryItemMappedAttribute = {
  matchedPermutation: string | null;
  newInput: ProductAttribute | ProductAttributeOption;
};

export const PDP_FORM_ID = 'pdp-input-form';

export const HIDDEN_OPTIONS = ['app_foil_die', 'design'];

export const isAttributeHidden = ({ name }: ProductAttribute): boolean => HIDDEN_OPTIONS.includes(name);

export const dependentInputsPredicate = (values: PDPFields, skipIfNotInitialized?: boolean) => (
  input: ProductAttribute | ProductAttributeOption
): boolean => {
  const dependentFieldValue = values[input.dependsOnOption];

  return (
    !input.dependsOnOption ||
    (skipIfNotInitialized && !dependentFieldValue) ||
    input.dependsOnValues.includes(dependentFieldValue)
  );
};

export const getCustomOptionDefaults = (product: Product): PDPFields => {
  const defaults = {};
  product.customOptions?.forEach(option => {
    const attribute = product.attributes.find(attribute => attribute.name === option.name);
    const isVisible = !attribute?.hidden;
    if (isVisible) {
      defaults[option.name] = option.values[0]?.label;
    }
  });
  return defaults;
};

export const getDefaults = (product: Product): PDPFields => {
  const valueMap = findNonOutOfStockPermutation(product, [], 0);
  if (!valueMap) {
    return {};
  }
  return valueMap;
};
const findNonOutOfStockPermutation = (product: Product, valueMap: PDPFields, index: number): PDPFields | false => {
  const { sku, outOfStockPermutations } = product;
  const maybeOutOfStockPermutations = outOfStockPermutations || [];
  const permutation = buildPermutationSkuFromValues(product, sku, valueMap);
  const isOutOfStock = maybeOutOfStockPermutations.some(key => key === permutation);
  if (isOutOfStock) {
    return false;
  }
  if (!isOutOfStock && index === product.attributes.length) {
    return valueMap;
  }
  for (let i = 0; i < product.attributes[index].options.length; i++) {
    const potentialOption = product.attributes[index].options[i];
    if (potentialOption.dependsOnOption && potentialOption.dependsOnValues) {
      // Check if the dependent value is in the value map
      const dependentValue = valueMap[potentialOption.dependsOnOption];
      if (!potentialOption.dependsOnValues.includes(dependentValue)) {
        continue;
      }
    }
    const nextValueMap = { ...valueMap };
    nextValueMap[product.attributes[index].id] = potentialOption.id;
    const result = findNonOutOfStockPermutation(product, nextValueMap, index + 1);
    if (result) {
      return result;
    }
  }
  return false;
};

export const inventoryItemStockMapper = (
  name: string,
  outOfStockPermutations: string[],
  formValues: FieldValues,
  defaults: FieldValues
) => (input: ProductAttribute | ProductAttributeOption): InventoryItemMappedAttribute => {
  let missingFormValueDefaults = {};

  if (Object.keys(formValues).length < Object.keys(defaults).length) {
    const missingFormkeys = Object.keys(defaults).filter(
      defaultKey => !Object.keys(formValues).find(formKey => defaultKey === formKey)
    );
    missingFormValueDefaults = missingFormkeys.reduce((acc, formKey) => {
      acc[formKey] = defaults[formKey];

      return acc;
    }, {});
  }

  // Find the permutation that represents the combination of the currently selected option values
  // and this potential one (input)
  const matchesSelectedFormValues = outOfStockPermutations.find(perm => {
    const keyValuePairs = Object.entries({ ...formValues, ...missingFormValueDefaults });

    return keyValuePairs.every(pair => {
      const [key, optionValue] = pair;
      const perms = perm.split('-');
      // We need to change any dashes or spaces in the option value to underscores to match the format of the permutations
      const formattedValue = (optionValue as string).toLowerCase();
      return key === name
        || perms.includes(formattedValue)
        || perms.includes(formattedValue.replaceAll('-', '_')) // Handle dashes
        || perms.includes(formattedValue.replaceAll(' ', '_')); // Handle spaces
    });
  });

  if (matchesSelectedFormValues?.length) {
    return {
      matchedPermutation: matchesSelectedFormValues,
      newInput: {
        ...input,
        disabled: true
      }
    };
  }

  return {
    matchedPermutation: null,
    newInput: input
  };
};

export const watchFields = (watch: UseFormWatch<PDPFields>, fields?: string[]): PDPFields =>
  !fields || !fields.length
    ? watch()
    : fields.reduce(
      (fields, field) => ({
        ...fields,
        [field]: watch(field)
      }),
      {}
    );

export const hydrateProductOptions = (attributes: ProductAttribute[], values: PDPFields): HydratedPDPFields => {
  return Object.entries(values).reduce((hydratedValues, [key, value]) => {
    const attr: ProductAttribute = attributes.find(({ id }) => key === id);
    return {
      ...hydratedValues,
      [key]: attr?.options.find(option => option.id === value)
    };
  }, {});
};

export const scrubValues = (prefix: string, values: PDPFields): PDPFields => {
  return Object.keys(values).reduce((clean, current) => {
    // Upsell card input names are prefixed to keep the refs straight
    // Here, we remove the prefixes to get back to the pure attribute name
    if (current.includes('upsell-card')) {
      const properKey = current.replace(prefix, '');
      return {
        ...clean,
        [properKey]: values[current]
      };
    }
    return {
      ...clean,
      [current]: values[current]
    };
  }, {});
};

export const maybePrefix = (prefix: string, fields: string[]): string[] => {
  if (prefix && fields?.length) {
    return fields.map(field => prefix + field);
  }
  return fields;
};

export const scrollIntoFirstErrorInput = (firstErrorInputId: string): void => {
  if (firstErrorInputId) {
    try {
      let inputContainerElement = document.querySelector(`[name="${firstErrorInputId}"]`);
      while (inputContainerElement.parentElement.id !== PDP_FORM_ID) {
        inputContainerElement = inputContainerElement.parentElement;
      }

      inputContainerElement.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      console.error('Error scrolling into form input: ', error);
    }
  }
};
